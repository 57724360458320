.search-drawer,
.form-drawer{
    .content{
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 0;
        right: -725px;
        width: 100%;
        max-width: 725px;
        height: 100%;
        background-color: $white;
        transition: right 0.8s;
        z-index: 10;

        @media (max-width: 904px) {
            top: inherit;
            right: 0;
            bottom: -85%;
            left: 0;
            max-width: 100% !important;
            max-height: 85%;
            transition: bottom 0.4s;
        }
    }

    &.active{
        .content{
            right: 0;

            @media (max-width: 904px) {
                bottom: 0;
            }

            .level{
                &__container{
                    .for-form{
                        .button-wrapper{
                            right: 0;
                            box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.05);

                            @media (max-width: 904px) {
                                bottom: 0;
                            }
                        }
                    }

                    .level{
                        &.hasButtons--bruk{
                            &::before{
                                @media (max-width: 904px) {
                                    bottom: 0;
                                }
                            }

                            .btn.bruk{
                                right: 64px;

                                @media (max-width: 904px) {
                                    right: 32px;
                                    bottom: 64px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .bg-overlay{
            opacity: 0.7;
            pointer-events: all;
        }
    }

    .bg-overlay{
        opacity: 0;
        transition: opacity 0.8s;
        pointer-events: none;

        @media (max-width: 904px) {
            transition: opacity 0.4s;
        }
    }
    .loader-wrapp {
        margin-top: 48px;
        @media (max-width: 904px) {
            margin-top: 32px;
        }
    }

    .content{
        .x-close{
            position: absolute;
            top: 54px;
            right: 57px;

            @media (max-width: 904px) {
                display: none;
            }
        }

        .level__container{
            position: relative;
            overflow: auto;

            .level{
                // display: none;
                position: relative;
                width: 100%;
                padding: 64px;
                background-color: $white;
                transition: 0.2s;

                @media (max-width: 904px) {
                    padding: 45px 34px;
                    padding-bottom: 0;
                    @media (max-height: 767px) {
                        padding-top: 30px;
                    }
                    @media (max-height: 735px) {
                        padding-top: 20px;
                    }
                }
                @media (max-width: 350px) {
                    @media (max-height: 824px) {
                        padding-top: 30px;
                    }
                    @media (max-height: 787px) {
                        padding-top: 20px;
                    }

                }

                .clear-selection{
                    display: block;
                    margin-bottom: 8px;
                    margin-left: auto;
                    font-size: 1rem;
                    line-height: 1.5;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        font-size: .875rem;
                    }
                }

                .level__title{
                    margin-bottom: 54px;
                    font-size: 32px;
                    line-height: 38px;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        margin-bottom: 32px;
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: $fw-book;
                    }
                }

                form.radio-list{
                    border-top: 1px solid $solid-40;

                    .input-group{
                        margin-bottom: 0;
                        padding: 24px 0;
                        border-bottom: 1px solid $solid-40;

                        @media (max-width: 904px) {
                            padding: 16px 0;
                        }

                        .radio-tag{
                            margin-bottom: 8px;
                            padding-left: 30px;
                            color: $solid-80;
                            font-size: 16px;
                            line-height: 24px;
                            font-weight: $fw-light;

                            @media (max-width: 904px) {
                                padding-left: 34px;
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }

                    .arrow-link{
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        width: 100%;
                        padding: 24px 56px 24px 30px;
                        font-size: 20px;
                        line-height: normal;
                        font-weight: $fw-light;
                        border-bottom: 1px solid $solid-40;
                        text-align: left;

                        @media (max-width: 904px) {
                            padding: 16px 44px 16px 30px;
                            font-size: 20px;
                            line-height: normal;
                            font-weight: $fw-light;
                        }

                        .title{
                            padding-right: 32px;
                            text-align: left;

                            @media (max-width: 904px) {
                                padding-right: 16px;
                            }
                        }

                        .icon{
                            position: absolute;
                            right: 10px;

                            @media (max-width: 904px) {
                                right: 0;
                            }

                            &--arrow-right-32-red{
                                @media (max-width: 904px) {
                                    width: 24px;
                                    height: 24px;
                                    background-image: url('./../images/icons/arrow/right/arrow-right-24-red.svg');
                                }
                            }
                        }
                    }
                }

                .link-list {
                    ul {
                        border-color: $solid-40;

                        li {
                            border-color: $solid-40;

                            .arrow-link {
                                position: relative;
                                padding-right: 56px;
                                font-size: 24px;
                                line-height: normal;
                                justify-content: flex-start;
                                text-align: left;
                                flex-wrap: wrap;
                                gap: 12px;

                                @media (max-width: 904px) {
                                    padding: 16px 44px 16px 0;
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-weight: $fw-light;
                                }

                                .title{
                                    padding-right: 32px;
                                    text-align: left;

                                    @media (max-width: 904px) {
                                        padding-right: 16px;
                                    }
                                }

                                .icon{
                                    position: absolute;
                                    right: 10px;
                                    pointer-events: none;

                                    @media (max-width: 904px) {
                                        right: 0;
                                    }

                                    &--check-32-green{
                                        @media (max-width: 904px) {
                                            width: 24px;
                                            height: 24px;
                                            background-image: url('./../images/icons/check/check-24-green.svg');
                                        }
                                    }
                                }

                                .filtered-items{
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    justify-content: flex-end;
                                    margin-top: -6px;
                                    margin-right: -12px;
                                    margin-bottom: -6px;
                                    margin-left: auto;

                                    li{
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        margin-top: 6px;
                                        margin-left: 12px;
                                        margin-bottom: 6px;
                                        padding: 5px 10px;
                                        background-color: $contrast-40;
                                        text-align: right;
                                        font-weight: $fw-book;
                                        border-radius: 3.75px;

                                        :first-child{
                                            margin-left: 0;
                                        }

                                        &:last-child{
                                            margin-right: 0;
                                        }

                                        &::after{
                                            content: "";
                                            display: block;
                                            width: 20px;
                                            height: 20px;
                                            margin-left: 10px;
                                            background-image: url('./../images/icons/x/x-12-white.svg');
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            background-color: $contrast-100;
                                            border-radius: 100%;
                                        }

                                        span{
                                            max-width: 270px;
                                            white-space: nowrap;
                                            display: block;
                                            font-size: 15px;
                                            line-height: 20px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 1; /* number of lines to show */
                                            line-clamp: 1;

                                            @media (max-width: 904px) {
                                                max-width: 200px;
                                                font-size: 14px;
                                                line-height: 18px;
                                            }

                                            @media (max-width: 599px) {
                                                max-width: 140px;
                                            }
                                        }
                                    }

                                    + .icon{
                                        margin-left: 21px;

                                        @media (max-width: 904px) {
                                            margin-left: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .btn {
                    &.bruk {
                        position: fixed;
                        bottom: 64px;
                        right: -725px;
                        margin: 0;
                        display: block;
                        min-width: 170px;
                        transition: right 0.8s;
                        z-index: 1;

                        @media (max-width: 904px) {
                            bottom: -138px;
                            transition: bottom 0.4s;
                        }

                        @media (max-width: 904px) {
                            margin-top: 16px;
                            padding: 8px 24px;
                            font-size: 15px;
                            line-height: 26px;
                            border-radius: 6px;
                            min-width: auto;
                        }
                    }
                }

                &.hasButtons{
                    padding-bottom: 160px;

                    @media (max-width: 904px) {
                        padding-bottom: 160px;
                    }

                    &--bruk{
                        padding-bottom: 200px;

                        @media (max-width: 904px) {
                            padding-bottom: 170px;
                        }

                        &::before{
                            content: "";
                            display: block;
                            position: fixed;
                            background-color: $white;
                            width: 100%;
                            height: 160px;
                            bottom: 0;
                            z-index: 1;
                            box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.05);
                            margin-left: -64px;

                            @media (max-width: 904px) {
                                height: 138px;
                                bottom: -85%;
                                left: 0;
                                margin: 0px;
                                transition: bottom 0.4s;
                            }
                        }
                    }
                }
            }

            .for-form{
                .note{
                    padding: 20px 30px;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: $fw-light;
                    background-color: $red-20;
                    border: 1px solid $red-100;

                    @media (max-width: 904px) {
                        padding: 20px 14px;
                        font-size: 14px;
                    }
                }

                .button-wrapper{
                    position: fixed;
                    right: -725px;
                    bottom: 0;
                    width: 725px;
                    margin: 0;
                    padding: 40px 70px;
                    background: $white;
                    transition: right 0.8s;

                    @media (max-width: 904px) {
                        width: 100%;
                        right: 0;
                        bottom: -138px;
                        padding: 30px 34px 64px;
                        transition: bottom 0.4s;
                    }

                    @media (max-width: 599px) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .btn{
                        @media (max-width: 599px) {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }
    &__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 186px 64px 45px;
        background-color: $warm-60;
        padding: 86px 64px 45px;
        height: 333px;
        align-items: flex-end;
        max-height: 33.3dvh;
        min-height: 178px;

        @media (max-width: 904px) {
            height: unset;
            min-height: unset;
            padding: 72px 36px 28px;
            @media (max-height: 762px) and (max-width: 350px) {
                padding-top: 28px;
            }
            @media (max-height: 700px) {
                padding-top: 28px;
            }
        }

        .close-this-drawer{
            &.icon--arrow-left-32-red{
                align-self: center;
                margin-right: 24px;
            }
        }

        h3{
            padding-right: 20px;
            font-size: 32px;
            font-weight: $fw-light;
            flex: 1;

            @media (max-width: 904px) {
                padding-right: 10px;
                font-size: 24px;
            }
        }

        .arrow-link{
            display: none;
            min-height: 48px;
            font-size: 18px;
            line-height: 26px;
            font-weight: $fw-book;
            flex: 1;

            @media (max-width: 904px) {
                min-height: 40px;
                font-size: 14px;
                line-height: 24px;
            }

            &.active{
                display: flex;
                align-items: center;
            }

            .icon{
                @media (max-width: 904px) {
                    width: 16px;
                    height: 16px;
                    background-image: url('./../images/icons/arrow/left/arrow-left-16-red.svg');
                }
            }
        }

        .btn-reset {
            margin-left: auto;
            font-size: 18px;
            line-height: 28px;
            font-weight: $fw-light;
            text-decoration: underline;
            color: $main-text-color;

            @media (max-width: 904px) {
              font-size: 16px;
              line-height: 22px;
            }
          }

        p{
            width: 100%;
            font-size: 20px;
            line-height: 28px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                font-size: 16px;
                line-height: 22px;
            }

            &:first-of-type{
                margin-top: 8px;
            }
        }
    }
    &-button {
        cursor: pointer;
        > input:read-only {
            cursor: pointer;
        }
    }

    &__footer {
        box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.05);
        background-color: $white;
        width: auto;
        height: 160px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 24px 64px;
        text-align: left;
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: flex-end;
        align-items: start;
        @media (max-width: 904px) {
            height: unset;
            margin: 0px;
            row-gap: 8px;
            padding: 24px 30px;
        }
        &::before{
            content: "";
            display: block;
            bottom: 0;
            z-index: 1;
        }
        .btn {
            @media (max-width: 904px) {
                padding: 8px 24px;
                font-size: .9375rem;
            }
        }
    }
    &--mobile-full {
        @media (max-width: 904px){
            .content {
                max-height: 100%;
                bottom: -100%;
                .x-close {
                    display: block;
                    top: 36px;
                    right: 36px;
                }
            }
        }
    }
}
.form-drawer {
    .level__container {
        flex: 1;
    }
}

// Add workplace drawer (FOR-419)
.add-workplace-btn{
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 26px;
    text-decoration: underline;

    @media (max-width: 599px) {
        margin-bottom: 40px;
        font-size: 16px;
        line-height: 22px;
    }
}

.add-workplace-instruction{
    padding-bottom: 12px;
    font-weight: $fw-light;
    font-size: 18px;
    line-height: 26px;
    border-bottom: 1px solid $solid-30;

    @media (max-width: 599px) {
        font-size: 15px;
        line-height: 22px;
    }
}

.button-wrapper{
    &--add-workplace{
        .close-this-drawer{
            margin-right: 60px;
            font-weight: $fw-light;
            font-size: 24px;
            text-decoration: underline;

            @media (max-width: 599px) {
                margin-right: 30px;
                font-size: 15px;
            }
        }

        .btn--secondary{
            @media (max-width: 599px) {
                font-size: 15px;
                padding: 10px 30px;
            }
        }
    }
}

.add-workplace-dialog{
    display: none;
    margin-bottom: 32px;
    padding: 24px 32px;
    background-color: $contrast-20;

    h6{
        margin-bottom: 6px;
        font-size: 18px;
        font-weight: $fw-medium;

        @media (max-width: 599px) {
            font-size: 16px;
        }
    }

    p{
        font-size: 18px;
        font-weight: $fw-light;

        @media (max-width: 599px) {
            font-size: 16px;
        }
    }

    .options{
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-top: 24px;

        @media (max-width: 599px) {
            gap: 60px;
        }

        button{
            font-size: 18px;
            font-weight: $fw-medium;
            text-decoration: underline;

            @media (max-width: 599px) {
                font-size: 16px;
            }
        }
    }
}
